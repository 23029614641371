import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c74c6e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["texture", "width", "height", "x", "y", "anchor-x", "anchor-y"]
const _hoisted_2 = ["x", "y"]
const _hoisted_3 = { sortableChildren: true }
const _hoisted_4 = ["textures", "width", "height", "x", "y", "zIndex", "anchor-x", "anchor-y", "playing"]
const _hoisted_5 = ["anchor", "x", "y", "scale"]
const _hoisted_6 = ["textures", "width", "height", "x", "y", "loop", "playing", "onLoop"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Application = _resolveComponent("Application")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "canvasContainer",
    class: "canvasContainer",
    style: _normalizeStyle({
      backgroundColor: _ctx.animationConfig.backgroundColor,
      backgroundImage: _ctx.animationConfig.background
        ? `url(/assets/animation/${_ctx.animationConfig.background})`
        : 'none',
      '--x': `${_ctx.animationProps.backgroundPositionX}px`,
      '--y': `${_ctx.animationProps.backgroundPositionY}px`,
      '--sizeX': _ctx.backgroundZoomX,
      '--sizeY': _ctx.backgroundZoomY,
    })
  }, [
    (_ctx.animationConfig.overlay)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "backgroundOverlay",
          style: _normalizeStyle({
        width: `${_ctx.containerWidth}px`,
        height: `${_ctx.containerHeight}px`,
        backgroundImage: _ctx.animationConfig.overlay
          ? `url(/assets/animation/${_ctx.animationConfig.overlay})`
          : 'none',
        '--percent': `${_ctx.overlayPercent}%`,
      })
        }, null, 4))
      : _createCommentVNode("", true),
    (
        _ctx.isLoaded &&
        _ctx.isContainerSet &&
        (_ctx.backgroundTexture || _ctx.animationConfig.backgroundSprite)
      )
      ? (_openBlock(), _createBlock(_component_Application, {
          key: 1,
          ref: "pixi",
          width: _ctx.containerWidth,
          height: _ctx.containerHeight,
          transparent: true,
          backgroundAlpha: 0,
          sortableChildren: true
        }, {
          default: _withCtx(() => [
            (_ctx.animationConfig.backgroundSprite)
              ? (_openBlock(), _createElementBlock("sprite", {
                  key: 0,
                  texture: _ctx.animationSprite.textures[_ctx.animationConfig.backgroundSprite],
                  width: _ctx.getObstacleWidth(),
                  height: _ctx.getObstacleHeight(),
                  x: _ctx.getObstacleX() + _ctx.animationProps.backgroundPositionX,
                  y: _ctx.getObstacleY() + _ctx.animationProps.backgroundPositionY,
                  "anchor-x": _ctx.animationConfig.stepAnchorX,
                  "anchor-y": _ctx.animationConfig.stepAnchorY
                }, null, 8, _hoisted_1))
              : _createCommentVNode("", true),
            (_ctx.animationConfig.introText && _ctx.animationProps.index === 0)
              ? (_openBlock(), _createElementBlock("text", {
                  key: 1,
                  anchor: [0.5, 1],
                  x: _ctx.containerWidth / 2,
                  y: _ctx.containerHeight * 0.95,
                  style: _normalizeStyle({
          fontFamily: 'Arial',
          fontWeight: 'bolder',
          fontSize: Math.min(_ctx.containerWidth / 20, 30),
          fill: '#ffffff',
          stroke: '#0C47F9',
          strokeThickness: Math.min(_ctx.containerWidth / 20, 30) / 3,
          align: 'center',
          breakWords: true,
          wordWrap: true,
          lineJoin: 'round',
          wordWrapWidth: _ctx.containerWidth,
        })
                }, _toDisplayString(_ctx.$t(
            `views.quiz.animations.${_ctx.visualizationType}.${_ctx.animationConfig.introText}`
          )), 13, _hoisted_2))
              : _createCommentVNode("", true),
            _createElementVNode("container", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stepCount, (stepIndex) => {
                return (_openBlock(), _createBlock(_TransitionGroup, { key: stepIndex }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getFrameCount(stepIndex - 1), (frameIndex) => {
                      return (_openBlock(), _createElementBlock("animated-sprite", {
                        key: frameIndex,
                        textures: _ctx.getObstacleAnimation(stepIndex - 1, frameIndex - 1),
                        "animation-speed": 0.1,
                        width: _ctx.getObstacleWidth(stepIndex - 1, frameIndex - 1, 0, true),
                        height: _ctx.getObstacleHeight(stepIndex - 1, frameIndex - 1, 0, true),
                        x: 
              _ctx.getObstacleX(stepIndex - 1, frameIndex - 1) +
              _ctx.animationProps.backgroundPositionX
            ,
                        y: 
              _ctx.getObstacleY(stepIndex - 1, frameIndex - 1) +
              _ctx.animationProps.backgroundPositionY
            ,
                        zIndex: _ctx.getObstacleZIndex(stepIndex - 1, frameIndex - 1),
                        "anchor-x": _ctx.getObstacleAnchorX(stepIndex - 1),
                        "anchor-y": _ctx.getObstacleAnchorY(stepIndex - 1),
                        loop: false,
                        playing: stepIndex <= _ctx.animationProps.index,
                        onComplete: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.obstacleCompleted && _ctx.obstacleCompleted(...args)))
                      }, null, 40, _hoisted_4))
                    }), 128))
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              (
            _ctx.animationProps &&
            _ctx.animationProps.index > 0 &&
            _ctx.animationConfig.displayText &&
            _ctx.completedIndex === _ctx.animationProps.index
          )
                ? (_openBlock(), _createElementBlock("text", {
                    key: 0,
                    anchor: [-0.2, _ctx.getObstacleAnchorY(_ctx.animationProps.index - 1)],
                    x: 
            _ctx.getObstacleX(_ctx.animationProps.index - 1) +
            _ctx.animationProps.backgroundPositionX
          ,
                    y: 
            _ctx.getObstacleY(_ctx.animationProps.index - 1) +
            _ctx.animationProps.backgroundPositionY
          ,
                    style: {
            fontFamily: 'Arial',
            fontWeight: 'bolder',
            fontSize: 42,
            fill: '#ffffff',
            stroke: '#000000',
            dropShadow: true,
          },
                    scale: _ctx.calcBackgroundZoom(_ctx.animationProps.index - 1) / 4
                  }, _toDisplayString(_ctx.$t(
              `views.quiz.animations.${_ctx.visualizationType}.${_ctx.getStep(_ctx.animationProps.index - 1).obstacleAnimation}`
            )), 9, _hoisted_5))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.vehicles, (vehicle, index) => {
                return (_openBlock(), _createElementBlock("animated-sprite", {
                  key: index,
                  textures: _ctx.getCurrentVehicleAnimation(vehicle, index),
                  "animation-speed": 0.1,
                  width: _ctx.getSpriteWidth(vehicle, true),
                  height: _ctx.getSpriteHeight(vehicle, true),
                  x: _ctx.getForegroundX(index),
                  y: _ctx.getSpriteY(vehicle),
                  zIndex: 1,
                  "anchor-x": 1,
                  "anchor-y": 1,
                  loop: _ctx.animationProps.foreground[index].tween?.isPlaying(),
                  playing: _ctx.animationProps.foreground[index].tween?.isPlaying(),
                  onLoop: () => _ctx.vehicleLoop(index)
                }, null, 40, _hoisted_6))
              }), 128))
            ])
          ]),
          _: 1
        }, 8, ["width", "height"]))
      : _createCommentVNode("", true)
  ], 4))
}
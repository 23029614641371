<template>
  <el-container :lang="$i18n.locale">
    <el-header>
      <div id="site_header">
        <a @click="$router.back()" target="_top" class="site-homelink">
          <img
            class="site-logo"
            src="@/assets/img/logo-biwas-waterline.png"
            title="www.biwas.de"
            alt="www.biwas.de"
          />
          <div class="title">
            <div id="site-title"></div>
            {{ $t('views.header.title') }}
          </div>
        </a>
      </div>
      <div class="background">
        <div class="title">
          <h1>{{ $t('views.home.title') }}</h1>
        </div>
      </div>
    </el-header>
    <el-main>
      <el-space wrap :style="{ gap: 0 }">
        <router-link
          :to="`/quiz/${item.id}?lang=${lang}`"
          v-for="item in list"
          :key="item.id"
          :style="{ '--color': item.settings.color }"
        >
          <div>
            <h2>
              <font-awesome-icon :icon="item.settings.icon" />
            </h2>
            <br />
            <h3>{{ item.title }}</h3>
            <!--<div v-if="item.description">{{ item.description }}</div>-->
          </div>
        </router-link>
      </el-space>
    </el-main>
  </el-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import * as quizService from '@/services/quiz-service';
import { Quiz } from '@/types/api/Quiz';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as authService from '@/services/auth-service';

@Options({
  components: { FontAwesomeIcon },
})
/* eslint-disable @typescript-eslint/no-explicit-any*/
export default class HomeView extends Vue {
  @Prop({ default: 'de' }) readonly lang!: string;
  list: Quiz[] = [];

  mounted(): void {
    authService.setLocale(this.lang, this.$i18n as any);

    //quizService.setIDs(true, this.lang);
    quizService.getQuizList(this.lang).then((list) => {
      this.list = list;
    });
  }
}
</script>

<style lang="scss" scoped>
.el-container {
  position: relative;
  width: 100%;
  height: 100dvh;
}

.el-header {
  --el-header-padding: 0;
  --el-header-height: 50vh;
  display: flex;
  flex-direction: column;
}

.el-main {
  position: relative;
}

.el-space {
  width: 100%;
  height: 100%;
}

@media (max-width: 512px) {
  .el-header {
    --el-header-height: 15vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 50%;
    height: 25%;
  }
}

@media (min-width: 512px) and (max-width: 1024px) {
  .el-header {
    --el-header-height: 50vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 25%;
    height: 50%;
  }
}

@media (min-width: 512px) and (max-width: 1024px) {
  .el-header {
    --el-header-height: 50vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 25%;
    height: 50%;
  }
}

@media (min-width: 1024px) {
  .el-header {
    --el-header-height: 75vh;
  }

  .el-space::v-deep(.el-space__item) {
    width: 12.5%;
    height: 100%;
  }
}

a {
  color: white;
  text-decoration-line: none;
}

a:visited,
a:hover {
  color: white;
}

.el-main {
  a {
    height: calc(100% - 2rem);
    background-color: var(--color);
    font-size: var(--font-size-default);
    padding: 1rem;
    display: flex;
    text-align: center;
    overflow: hidden;

    > div {
      margin: auto;
      vertical-align: center;
    }

    h2 {
      display: inline;
      font-weight: var(--font-weight-semibold);
      font-size: 2.5rem;
      color: white;
    }

    h3 {
      hyphens: auto;
      //line-height: 1rem;
      margin-block-start: 0.5rem;
      margin-block-end: 0;
      color: white;
    }
  }
}

.el-main {
  --el-main-padding: 0;
}

.background {
  width: 100%;
  height: 100%;
  background-color: var(--color-main);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .title {
    padding-left: 3vw;
    padding-bottom: 5rem;

    h1 {
      margin: 0;
      font-size: min(6vw, 3.8rem);
      color: white;
    }
  }
}

@media (min-width: 1200px) {
  .background {
    .title {
      padding-left: 2.4rem;
    }
  }
}

@media (min-width: 512px) and (max-width: 1024px) {
  .background {
    .title {
      padding-bottom: 3rem;
    }
  }
}

@media (max-width: 512px) {
  .background {
    .title {
      padding-bottom: 1rem;
    }
  }
}
</style>
